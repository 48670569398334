export const DOMAINAPI = process.env.REACT_APP_DOMAINAPI || 'https://main-api.solar-manager.ch';
export const HEALTH_CHECK_API = process.env.REACT_APP_HEALTH_CHECK_API || 'https://maintenance.solar-manager.ch';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL || 'support@solarmanager.ch';

export const DEFAULT_TIMEZONE = 'UTC';
export const TERMS_AND_CONDITIONS_LINK = 'https://rctportal.blob.core.windows.net/web/terms_de.pdf';
export const PRIVACY_POLICY_LINK = 'https://www.rct-power.com/de/datenschutz.html';

export const APP_ID = 'rctEndUserApp';
export const THREE_S_APP_ID = 'threeSEndUserApp';
export const RENNERGY_APP_ID = 'rennergyEndUserApp';
export const SOLAR_MANAGER_APP_ID = 'solarManagerEndUserApp';
export const ETAVIS_APP_ID = 'etavisEnergyManagerEndUserApp';
export const E_CONNECT_APP_ID = 'energyConnectEndUserApp';
export const BKW_APP_ID = 'homeEnergyConnectEndUserApp';
export const TOBLER_APP_ID = 'smartSolarEndUserApp';
export const ENVISION_APP_ID = 'envisionEndUserApp';
export const ASKOMA_APP_ID = 'askomaEndUserApp';
export const HELION_APP_ID = 'helionEndUserApp';
export const EKZ_APP_ID = 'EKZEndUserApp';
export const EZEE_APP_ID = 'ezeeEndUserApp';
export const CKW_APP_ID = 'ckwEndUserApp';
export const FEBESOL_APP_ID = 'febesolEndUserApp';
export const THERMONDO_APP_ID = 'thermondoEndUserApp';
export const ABB_APP_ID = 'abbEndUserApp';
export const SOLAREINS_APP_ID = 'solareinsEndUserApp';
export const ACTENSYS_APP_ID = 'actensysEndUserApp';
export const SOLTOP_APP_ID = 'soltopEndUserApp';
export const RCT_APP_ID = 'rctEndUserApp';
export const SUEDDEUTSCHLAND_APP_ID = 'sueddeutschlandEndUserApp';
